Lyte.Component.register("crux-tag-popover", {
_template:"<template tag-name=\"crux-tag-popover\"> <lyte-popover lt-prop-origin-elem=\".cxPropOnFocusTag .moreTagOption\" class=\"moreTagOptionPopover\" lt-prop-freeze=\"false\" lt-prop-show-close-button=\"false\" lt-prop-show=\"true\" lt-prop-type=\"box\" lt-prop-max-width=\"200\" lt-prop-width=\"200\" lt-prop-wrapper-class=\"nLvTagsMoreOptionhandle\" lt-prop-duration=\"{{durationUndefined}}\" on-close=\"{{method('closePopover')}}\"> <template is=\"registerYield\" yield-name=\"popover\"> <lyte-popover-content class=\"tagPopoverLab\"> <div> <ul class=\"listview_taglists m0 dIB\"> <template is=\"for\" items=\"{{remainingValues}}\" item=\"val\" index=\"index\"> <li class=\"pL0\"> <span class=\"tagElementList f13 dIB cxAddedTags {{concat('cxTag',cruxGetTagColorIndex(val.color_code))}}\" style=\"background: {{if(ifEquals(val.color_code,'noFill'),'transparent',concat(val.color_code,' !important;'))}}; color : {{cruxGetPicklistFontColor(val.color_code)}}\">{{val.name}}</span> </li> </template> </ul> </div> </lyte-popover-content> </template> </lyte-popover> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1,1,1,1]},{"type":"for","position":[1,1,1,1],"dynamicNodes":[{"type":"attr","position":[1,1],"attr":{"style":{"name":"style","helperInfo":{"name":"concat","args":["'background: '",{"type":"helper","value":{"name":"if","args":[{"type":"helper","value":{"name":"ifEquals","args":["val.color_code","'noFill'"]}},"'transparent'",{"type":"helper","value":{"name":"concat","args":["val.color_code","' !important;'"]}}]}},"'; color : '",{"type":"helper","value":{"name":"cruxGetPicklistFontColor","args":["val.color_code"]}}]}}}},{"type":"text","position":[1,1,0]}]},{"type":"componentDynamic","position":[1]}]},{"type":"componentDynamic","position":[1]}],
_observedAttributes :["durationUndefined"],//No I18n
	data : function(){
		return {
            durationUndefined : Lyte.attr("number", {default : undefined})//NO I18n
		}		
	},
	actions : {
		showMoreTags : function(){
			clearTimeout(this.getData("cxParent").hideTimeout);//No I18n
		},
		hideMoreTags : function(){
			this.getData("cxParent").hideTags();//No I18n
		}
	},
	methods : {
		closePopover : function(){
			setTimeout(function(){
				document.querySelector(".cxPropOnFocusTag").classList.remove("cxPropOnFocusTag");//No I18n
			}, 10);
		}
	}
});
