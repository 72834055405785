Lyte.Mixin.register("crux-lookup-mixin", {//No I18n
	lookupInit : function(comp,modId,lookup,event,ddelem){
		var self = this;
		var prom = [this.executeMethod("fetchModuleData", modId)];
		if(this.data.cxPropRelatedId){ //We can eliminate this api call by using lookup-advanced
			var arr = [{id : this.data.cxPropRelatedId, name : _cruxUtils.getI18n("crm.related.contact.account", this.data.module.plural_label, this.data.cxPropRelatedName)}, {id : "2", name : _cruxUtils.getI18n("crm.allcontact.show", this.data.module.plural_label)}];//no i18n
			this.setData("headerOptions", arr);//no i18n
			this.setData("selectedHeaderValue", this.data.cxPropRelatedId);//no i18n	
			prom.push(this.executeMethod("fetchModuleData", this.data.cxPropRelatedModuleId));
		}
		Lyte.resolvePromises(prom).then(function(resp){
			var mod = resp[0];
			if(comp.$node){
				comp.setData("module", mod);//no i18n
				self.setModuleData(comp, mod, lookup, ddelem, event);
			}
		})
	},
	setModuleData : function(comp, mod, lookup, ddelem, event){
		comp.setData('moduleName',mod.module_name);// No I18n
		var f= mod.display_field.api_name
		comp.setData('displayField',f);//no i18n
		if(lookup){
			comp.getRecs()
		}else{
			comp.beforeShowFunc(event,ddelem,true)
		}
	},
	constructSearchFilter : function(disp, val){
		var result = {comparator : 'contains' , field : {api_name:disp},value:val};//no i18n
		if(this.data.modulenameUicomp == "Products" || this.data.moduleName == "Products"){
			if(this.data.module.fields){
				var field = this.data.module.fields.filterBy({api_name : "Product_Code"});//No I18n
				if(field.length && field[0].visible){
					result = {group_operator : "or", group : [result, {comparator : "contains", field : {api_name : "Product_Code"}, value : val}]}//No I18n
				}
			}
		}
		if(this.data.header){
			for(var i=0; i<this.data.header.length; i++){
				if(this.data.header[i].api_name == disp){
					continue;
				}
				switch(this.data.header[i].cxTypeMapping){
					case "email":
					case "phone":
					result = {group_operator : "or", group : [result, {comparator : "contains", field : {api_name : this.data.header[i].api_name}, value : val}]}//No I18n
					break;
				}
			}			
		}
		return JSON.stringify(result) 
	}
});

