Lyte.Component.register("crux-error-message", {
_template:"<template tag-name=\"crux-error-message\"> <template is=\"if\" value=\"{{cxPropErrorYield}}\"><template case=\"true\"> <lyte-yield yield-name=\"errorYield\" error-message=\"{{errorMessage}}\" cx-field=\"{{cxPropField}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\"></lyte-yield> </template><template case=\"false\"> <span id=\"{{cxPropId}}\" class=\"cruxErrMsg cruxErrorMsgDesc {{cxPropErrorSpanClass}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\">{{unescape(errorMessage)}}</span> </template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"insertYield","position":[1]}]},"false":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"text","position":[1,0]}]}},"default":{}}],
_observedAttributes :["errorMessage","cxPropId","cxPropErrorYield","cxPropField","cxPropErrorZcqaPrefix","cxPropErrorZcqaSuffix","cxPropErrorSpanClass"],//No I18n
	data : function(){
		return {
			errorMessage : Lyte.attr("string"), //No I18n
			cxPropId: Lyte.attr("string", {"default": ""}), //NO i18n
			cxPropErrorYield : Lyte.attr("boolean", {default : false}),//No I18n
			cxPropField : Lyte.attr("object"),//No I18n
			cxPropErrorZcqaPrefix : Lyte.attr("string", {default : ""}),//No I18n
			cxPropErrorZcqaSuffix : Lyte.attr("string", {default : "Error"}),//No I18n
			cxPropErrorSpanClass : Lyte.attr("string")//No I18n
		}
	},
	observeMessage : function(){
        var cruxErrorNode = this.$node;
        var errorMsgSpan = cruxErrorNode.querySelector("span"); //NO I18N
        if(this.data.errorMessage && !this.data.cxPropErrorYield && (errorMsgSpan.scrollWidth > cruxErrorNode.offsetWidth)){
            $L(cruxErrorNode).addClass("cxErrorMsgMultiLines");    //NO I18N
        }
	}.observes("errorMessage").on("didConnect")//No I18n
});
