Lyte.Mixin.register("crux-element-validation", {//No I18n
	isValidDate : function(dateVal, datepat){
		if(!dateVal)
		{
			return false;
		}
		datepat = datepat ? datepat : this.getData("cxPropDatePattern");//no i18n
		//pattern changed to uppercase as day(01,02,..), month(01,02..) and year are represented as tokens DD, MM and YYYY in moment
		//lowercase 'dd' and 'mm' represents day of week('Su','Mo'..) and minutes respectivetly
		datepat = datepat.toUpperCase();
		datepat = datepat.replace(/'/g,'');
		dateVal = dateVal.replace(/'/g,'');
		try{
			return $L.moment(dateVal,datepat, {i18n : true}).validate();
		}catch(e){
			return false;
		}
	},
	isEmoji : function(str) {
	    var regex= /([\uD800-\uDBFF][\uDC00-\uDFFF])/i;
	    if(regex.test(str)) {
	        return true;
	    }else{
	        return false;
	    }
	},
	SplitWord : function(str){
		str = str.split(",")
		var res=[],temp
		var ind;
		for(var i=0;i<str.length;i++){
			ind = str[i].indexOf("\\");
			if(ind!=-1 && str[i].length-2 == ind){
				temp = str[i].slice(0,str[i].length-2) + ","+str[i+1]
			 	Lyte.arrayUtils(res, "push", temp);//No I18n
				i = i+1;
			}else if(str[i] != ""){
				Lyte.arrayUtils(res, "push", str[i]);//No I18n
			}
		}
		return res.length == 1 ? res[0] : res;;
	},
	dateValidate : function(currObj){
		var ret = this.isValidDate(currObj.value.trim(), this.getData("cxPropDatePattern"));//no i18n
		if(!ret){
			var field = this.getData("cxPropField");//no i18n
			var fldLabel = field.errorFieldLabel ? field.errorFieldLabel : field.field_label;//No I18n
			var replacedFldLabel=fldLabel.replace(/_____/g,')').replace(/____/g,'(').replace(/___/g,'.');
			var alertMsg = _cruxUtils.getI18n("crm.field.valid.check",Lyte.Component.registeredHelpers.cruxEncodeHTML(replacedFldLabel)); //No I18N
    		this.showAlert(alertMsg,currObj);
		 	currObj.setAttribute("autocomplete","off");
		}
		return ret;
	},
	patternValidate : function(fldName, fldLabel, currObj, doNotShowAlert){
		if(!this.isValidDate(currObj.value, this.getData("cxPropDatePattern")) && this.$node.querySelector("lyte-input").calendarDiv){
			this.$node.querySelector("lyte-input").calendarDiv.querySelector("lyte-calendar").revertToToday();//No I18n
		}
	},
	showAlertMsg : function(alertMsg){
		var ele = document.getElementById("cruxElementAlert");
		if(!ele){
			ele = Lyte.Component.render("lyte-alert", {id : "cruxElementAlert", ltPropShowCloseButton : false, ltPropButtonPosition : "center",
				ltPropButtons : [{"type":"accept","text":_cruxUtils.getI18n('crm.mb.newversion.msg4'),"appearance":"primary"}], ltPropContentAlign : "center"}, "body");//No I18n
		}
		var span = document.createElement("span");
		span.innerHTML = alertMsg;
		ele.ltProp({"secondaryMessage" : span.innerText,top : '0px'});//No I18n
		ele.ltProp("show", true);//No I18n
		var comp = this;
		ele.setMethods({onClose : function(){
			if(comp && comp.$node.querySelector("lyte-input")){
				comp.$node.querySelector("lyte-input").focus();//No I18n
			}else if(comp && comp.$node.querySelector("lyte-number")){//no i18n
				comp.$node.querySelector("lyte-number").focus();//No I18n
			}
		}.bind(this)})
	},
	showAlert : function(alertMsg,currObj){
		if(this.getMethods("onError")){
			var check = false;
			check = this.executeMethod("onError", alertMsg , this);//No I18n
			if( !check ){
				return false;
			}
		}
		if(currObj){
			currObj.focus();
		}
		this.showAlertMsg(alertMsg)
	},
	showMsgBox : function(alertMsg,type,properties){
		if(this.getMethods("onError")){
			var check = false;
			check = this.executeMethod("onError", alertMsg , this);//No I18n
			if( !check ){
				return false;
			}
		}
		var ele = document.getElementById("lyteMsgBox");
        if( !properties ){
                properties = {};
        }
        properties.ltPropType = type;
        properties.ltPropMessage = alertMsg;
        properties.id = "lyteMsgBox";
        if(ele){
        	ele.ltProp("show", false);//No I18n
        }
        ele = Lyte.Component.render("lyte-messagebox", properties, "body");//No I18n
        ele.setMethods( 'onClose', function(arg){ //no i18n
                document.body.removeChild( arg.$node );
        })
//      ele.ltProp({"message" : alertMsg,"type" : type});//No I18n
        ele.ltProp("show", true);//No I18n
	},
	// splitDateVal : function(dateval, userDateFormatTemp){
	// 	userDateFormatTemp = userDateFormatTemp ? userDateFormatTemp.toLowerCase() : this.getData("cxPropDatePattern").toLowerCase();//No I18n
	// 	userDateFormatTemp = userDateFormatTemp.replace(/'/g,'');
	// 	var year = userDateFormatTemp.indexOf("yyyy");
	// 	var dateelements = new Array(3);
	// 	dateelements[2] = dateval.substring(year, year+4);
	// 	var month = userDateFormatTemp.indexOf("mm");
	// 	dateelements[1] = dateval.substring(month, month+2);
	// 	var date = userDateFormatTemp.indexOf("dd");
	// 	dateelements[0] = dateval.substring(date, date+2);
	// 	return dateelements;
	// },
	getDateInUserDatePattern : function(newDate, userPattern){
		userPattern = userPattern ? userPattern : this.getData("cxPropDatePattern");//no i18n
		userPattern = userPattern.toUpperCase();
		return $L.moment(newDate).format(userPattern);
	},
	getTimeInUserFormat : function(dateObj, timeFormat) {//No I18n
		var hr = dateObj.getHours();//No I18n
		var min = (dateObj.getMinutes()<10)?("0"+dateObj.getMinutes()):dateObj.getMinutes();//No I18n
		var ret = "";
		timeFormat = timeFormat ? timeFormat : this.getData("cxPropTimeFormatInput");//no i18n
		if ( timeFormat == "12") {  //No I18n
			var mer = "PM";//No I18n
			if(hr > 12){
				hr = hr-12;
				if(hr < 10){
					ret = "0"+hr;
				}
				else{
					ret = hr;
				}
				// ret = (hr-12);
			}
			else if(hr == 0){
				ret = "12";//No I18n
				mer = "AM";//No I18n
			}
			else{
				ret = hr;
				if(hr<10){
					ret = "0"+ret;//No I18n
				}
				if(hr != 12){
					mer = "AM";					//No I18n
				}
			}
			ret+=":"+min+" "+_cruxUtils.getI18n(mer);
		}
		else{
			if(hr<10 && hr.toString().length < 2){
				hr = "0"+hr;//No I18n
			}
			ret = hr+":"+min;
		}
		return ret;
	},
	getDateObject : function(dateval,time, datePattern){
        datePattern = datePattern ? datePattern.toUpperCase() : this.getData("cxPropDatePattern").toUpperCase();//No I18n
		datePattern = datePattern.replace(/'/g,'');
        var dateObj = $L.moment(dateval,datePattern, {i18n : true}).getDObj();
		if(!dateObj){
			return "";
		}
		if(time){
        	var timeSplit = time.match(/(\d{1,2}):(\d{1,2})/)
        	time = time.replace(_cruxUtils.getI18n('AM'),'AM').replace(_cruxUtils.getI18n('PM'),'PM')
        	var meridium = time.match(/([A,P]M)/)
        	time = time.split(":");
        	if(meridium){
	        	var timeConv = this.convertTimeTo24HoursFormat(parseInt(timeSplit[0]),meridium[1])
		        dateObj.setHours(timeConv);
        	}
        	else{
        		dateObj.setHours(time[0]);
        	}
			dateObj.setMinutes(time[1].split(" ")[0]);
        }
        return dateObj;
    },
    convertTimeTo24HoursFormat : function(hrs,meridiem){
        meridiem = meridiem.toLowerCase();
        if(meridiem == "am"){  //No I18N
                return (hrs == 0)?12:(hrs == 12)?0:hrs;
        }else if(meridiem == "pm"){//No I18N
        		return (hrs == 12)?hrs:hrs+12;
        }else if(meridiem == _cruxUtils.getI18n("AM") || _cruxUtils.getI18n("am") == meridiem ){//No I18N
        	 return (hrs == 0)?12:(hrs == 12)?0:hrs;
        }else if(meridiem == _cruxUtils.getI18n("PM") || _cruxUtils.getI18n("pm") == meridiem ){//No I18N
        	 return(hrs == 12)?hrs:hrs+12;
        }
    },
	isValidInteger : function(val){//No I18N
		if(val == "") { return true; }
		var re=/^-?\d+(,(\d)+)*$/
		if (isNaN(val) || val.indexOf(".")!=-1 || !re.test(val)) {
			return false;
		}
		return true;
    },
    isValidDecimal: function(val){//No I18N
		if(val == "") { return true; }
		var reg = /^-?(\d+)?(\.){0,1}(\d+)*$/;
        var valArr = reg.exec(val);
        if(!valArr){
        	return false;
        }
        if(valArr[1]==undefined && valArr[3] == undefined){
        	return false;
        }
        return true;
    },
    decimalLengthCheck : function(val, fieldProp){//no i18n
    	if(val == "") { return true; }
    	var reg = /^-?(\d+)?(\.){0,1}(\d+)*$/;
        var valArr = reg.exec(val);
        if(!valArr){return false;}
        var decimalVal = valArr[3];
        fieldProp = fieldProp ? fieldProp : this.getData("cxPropField");//No I18n
    	if(decimalVal&&decimalVal.length>fieldProp.decimal_place){
    		return false;
    	}
    	return true;
    },
    convertCurrencyValue : function(isoCode,value, decimal_base, currencyDetails, currencyCode, exchangeRate, exchangeRateFinance, homeCurrency, displayCurrency, formattedCurrency){//no i18n
		var retString = formattedCurrency != undefined ? parseFloat(value) : this.getNumberWithCommas(parseFloat(value),",",".");//No I18n
		decimal_base = decimal_base ? decimal_base : this.getData("cxPropDefaultRoundOff");//No I18n
		var floatvalue = parseFloat(value);
		var len = (parseInt(floatvalue+"")+"").length;
		var homeVal = formattedCurrency != undefined ? formattedCurrency : Number(parseFloat(value).toPrecision(len+decimal_base)).toFixed(decimal_base);
		currencyDetails  = currencyDetails ? currencyDetails : this.getData("cxPropCurrencyDetails");//No I18n
		currencyCode = currencyCode ? currencyCode : this.getData("cxPropCurrencyCode");//No I18n
		displayCurrency = displayCurrency == false ? false : true;
		if(currencyDetails[currencyCode]&&isoCode){
			decimal_base = parseInt(currencyDetails[currencyCode].decimals);//No I18n
			var decimal_curr = parseInt(currencyDetails[isoCode].decimals);
			// exchangeRate = exchangeRate ? exchangeRate : currencyDetails[isoCode].er;
			var calcVal;
			if(exchangeRateFinance){
				calcVal = value*exchangeRateFinance;
			}
			else if(exchangeRate){
				calcVal = value/parseFloat(exchangeRate)+"";
			}
			else{
				calcVal = value/parseFloat(currencyDetails[isoCode].er) + "";//No I18n
			}
			var defSep = currencyDetails[isoCode].format.split("|");//No I18n
			var refSep = currencyDetails[currencyCode].format.split("|"); //refSep[0] for thousands separator refSep[1] for decimal separator
			var defVal = formattedCurrency != undefined ? formattedCurrency : this.getNumberWithCommas(parseFloat(value).toFixed(decimal_curr),defSep[0],defSep[1]);
			retString = displayCurrency ? currencyDetails[isoCode].symbol+" " : "";
			if(isoCode == currencyCode || defVal == 0){
				retString += defVal;//No I18n
			}else if(homeCurrency){
				retString = retString+(defVal)+" ("+currencyDetails[currencyCode].symbol+" "+this.getNumberWithCommas(homeCurrency,refSep[0],refSep[1])+")";//No I18n
			}else{
				var homeVal1 =  parseFloat(calcVal).toPrecision((parseInt(calcVal)+"").length+decimal_base);
				retString = retString+(defVal)+" ("+currencyDetails[currencyCode].symbol+" "+this.getNumberWithCommas(parseFloat(homeVal1).toFixed(decimal_base),refSep[0],refSep[1])+")";//No I18n
			}
		}else if(this.getData("cxPropDefaultOrgCurrency")){//No I18n
			var defSep = this.getData("cxPropDefaultOrgCurrency") && typeof this.getData("cxPropDefaultOrgCurrency") == "object" && this.getData("cxPropDefaultOrgCurrency").format ? this.getData("cxPropDefaultOrgCurrency").format.split("|") : [',','.'];//No I18n
			retString = displayCurrency ? this.getData("cxPropDefaultOrgCurrency")+" " : "";//No I18n
			retString = retString+this.getNumberWithCommas(homeVal, defSep[0], defSep[1], formattedCurrency != undefined);
		}else if(currencyDetails && currencyCode && currencyDetails[currencyCode]){//No I18n
			var defSep = currencyDetails[currencyCode].format.split("|"); //refSep[0] for thousands separator refSep[1] for decimal separator
			retString = displayCurrency ? currencyDetails[currencyCode].symbol+" " : "";
			retString = retString+this.getNumberWithCommas(homeVal, defSep[0], defSep[1], formattedCurrency != undefined);
		}
		return retString;
	},
	getNumberWithCommas : function(number,k_sep,dec_sep, formattedCurrency) {//no i18n
		if(formattedCurrency){
			return number.toString();
		}
		k_sep = (k_sep)?k_sep:",";
		dec_sep =(dec_sep)?dec_sep:".";
	    var parts = number.toString().split(".");
	    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, k_sep);
	    return parts.join(dec_sep);
	},
	isValidPhoneNo : function(fldValue, len){
		if(!this.validateLength(fldValue, len)){
			return false;
		}
		var re= /^([\+]?)(?![\.-])((?=([\.\/-]?[ ]?[\da-zA-Z]+))\3+|([ ]?\((?![\.-])(?=[ \.-]?[\da-zA-Z]+)([ \.-]?[\da-zA-Z]+)+\)(?!\.)([ -][\da-zA-Z]+)?)+)+((([,]+)?[;]?([\*#]+)?[\da-zA-Z]+)+)?[#;]?$/;
        fldValue=fldValue.replace(/[;,](?!.)/,"");
       	fldValue=fldValue.replace(/\s{2,}/g," ");
       	fldValue=fldValue.replace(/\s-/g,"-");
       	fldValue=fldValue.replace(/-\s/g,"-");
       	fldValue=fldValue.replace(/\s\./g,"\.");
       	fldValue=fldValue.replace(/\.\s/g,"\.");
       	fldValue=fldValue.replace(/\s;/g,";");
       	fldValue=fldValue.replace(/;\s/g,";");
       	fldValue=fldValue.replace(/\s,/g,",");
        fldValue=fldValue.replace(/,\s/g,",");
       	fldValue=fldValue.replace(/\(\s/g,"(");
       	fldValue=fldValue.replace(/\s\)/g,")");
       	var tempVal = fldValue;
       	tempVal=tempVal.replace(/\)(?![\s;,\-\/])/g, ") ");
       	var len=tempVal.length;
       	if(tempVal == ""){
        		return false;
        }
       	var tempfldVal=tempVal[0];
       	for(var i=1;i<len;i++)
       	{
       	  	if(tempVal[i]=="(" && tempVal[i-1]!=" ")
       	  	{
       	  	   	tempfldVal=tempfldVal+" "+tempVal[i];
       	  	}
        	else
          	{
       	  		tempfldVal=tempfldVal+tempVal[i];
       	  	}
       	}
       	tempfldVal=tempfldVal.replace(/^\s+/,"");
       	tempfldVal=tempfldVal.replace(/\s+$/,"");
        fldValue=tempfldVal;
        if(re.test(fldValue))
       	{
       		return true;
        }
        return false;
	},
	isValidTwitter : function(twitterId){//no i18n
    	if(twitterId==""){
    		return true;
    	}
    	if(twitterId.trim().length<=50 && twitterId.trim().length>0){
            var twNamePattern=/^[a-zA-Z0-9_]*$/;
            return twNamePattern.test(twitterId);
        }
        return false;
    },
    isValidWebUrl : function(url){//No I18N
		if(url == "") { return true; }
        var httpProtocol = "http"; //No I18N
        var httpsProtocol = "https"; //No I18N
	var urlregex = new RegExp("^("+httpProtocol+":\\/\\/www.|"+httpsProtocol+":\\/\\/www.|ftp:\\/\\/www.|www.|"+httpProtocol+":\\/\\/|"+httpsProtocol+":\\/\\/|ftp:\\/\\/|){1}[^\x00-\x19\x22-\x27\x2A-\x2C\x2E-\x2F\x3A-\x3F\x5B-\x5E\x60\x7B\x7D-\x7F\x20]+(\\.[^\x00-\x19\x22\x24-\x2C\x2E-\x2F\x3C\x3E\x40\x5B-\x5E\x60\x7B\x7D-\x7F\x20]+)+([/?].*)*$");
        return urlregex.test(url);
    },
    getNumberValueForView : function(value, type, isoCode, uitype, currencyDetails, decimal_base, currencyCode, exchangeRate, exchangeRateFinance, homeCurrency, displayCurrency, formattedCurrency, separator){
    	if(type == "currency" || uitype == 39 || uitype == 36 || uitype == 77){
    		currencyDetails = currencyDetails ? currencyDetails : this.getData("cxPropCurrencyDetails");//No I18n
    		if(isoCode && currencyDetails){
    			for(var key in currencyDetails){
    				if(currencyDetails[key].symbol == isoCode){
    					isoCode = key;
    					break;
    				}
    			}
    		}
    		else{
    			isoCode = undefined;
    		}
    		value = this.convertCurrencyValue(isoCode, value, decimal_base, currencyDetails, currencyCode, exchangeRate, exchangeRateFinance, homeCurrency, displayCurrency, formattedCurrency);
    	}
    	// else if(uitype == 40){
    	// 	value = parseFloat(value).toFixed(9);
    	// }
    	else if(uitype !=38 && uitype != 34 && uitype != 40 && type == "double" && value % 1 == 0){
    		value = value/1+".0";
    	}
    	if(separator && typeof Search != "undefined" && Search.formatNumber){
    	    return Search.formatNumber(value);
    	}
    	return value.toString();
    },
    validateNumberField : function(val, field, maxLength, maxvalue, minvalue){
    	if(!val || val.trim() == ""){
    		return this.validateMandatory(true);
		}
		if(maxLength && val.length > maxLength){
			 if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR04, this);//No I18n
			}
			else{
				this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.field.length.check", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			}
			return false;
		}
		if(maxvalue && val > maxvalue){
			if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR02, this);
			}
			else{
				this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.custom.field.less.than.equalto", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label), maxvalue));				
			}
			return false;
		}
		if(minvalue && val < minvalue){
			if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR02, this);
			}
			else{
				this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.custom.field.greater.than.equalto", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label), minvalue));				
			}
			return false;
		}
		if(field.data_type == "integer" || field.data_type == "bigint"){
			var check = this.isValidInteger(val);
			if(!check){
				if(this.getMethods("onError")){
					this.executeMethod("onError", this.errorCodes.ERR02, this);//No I18n
				}
				else{
					this.setData("cxPropErrorMessage", _cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
				}
			}
			return check;
		}
		else{
			var check = this.isValidDecimal(val);
			if(check){
				check = this.decimalLengthCheck(val, field);
				if(!check){
					if(this.getMethods("onError")){
						this.executeMethod("onError", this.errorCodes.ERR03, this);//No I18n
					}
					else{
						this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.field.valid.decimal.check2", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label), field.decimal_place));//No I18n
					}
				}
			}
			else{
				if(this.getMethods("onError")){
					this.executeMethod("onError", this.errorCodes.ERR02, this);//No I18n
				}
				else{
					this.setData("cxPropErrorMessage", _cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
				}
			}
			return check;
		}
    },
    validatePhoneNumber : function(field, value){
    	if(!value || value.trim() == ""){
    		if(!this.validateMandatory(true)){
    			this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
			return false;
    		}
    		if(value){
    			if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR02, this);//No I18n
			}
			else{
	    		this.setData("cxPropErrorMessage", _cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			}
			this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
			return false
    		}
    		this.setData("cxPropErrorMessage", "");//No I18n
		return true;
		}
		var check;
		if(this.data && this.data.cxPropEnableCountryCode && value.startsWith("+")){
			try
			{
				check = ZlibPhoneNumber.isValidNumber(value);
			}
			catch(e)
			{
				if(typeof murphy !== "undefined"){
					murphy.error(e);
				}
				check = false;
			}

		}
		else{
			check = this.isValidPhoneNo(value);
		}
    	if(!check){
    		if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR02, this);//No I18n
			}
			else{
	    		this.setData("cxPropErrorMessage", _cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			}
			this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
    	}
    	else{
    		this.setData("cxPropErrorMessage", "");//No I18n
    	}
    	return check;

    },
    errorCodes : {ERR01 : "value_empty", ERR02 : "value_invalid", ERR03 : "decimal_check", ERR04 : "maxlength_check"},//No I18n
    validateLength : function(value, len){
    	len = len ? len : this.data.cxPropMaxlength;
     	if(len && value.length > len){
			if(this.getMethods("onError")){
				this.executeMethod("onError", this.errorCodes.ERR04, this);//No I18n
			}
			else{
				this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.field.length.check", Lyte.Component.registeredHelpers.cruxEncodeHTML(this.getData("cxPropField").field_label)));//No I18n
			}
			this.$node && this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
			return false;
		}
		return true;
     },
     validateText : function(field, val, len, layout){
     	if(!this.validateMandatory(!val || val.trim() == "", undefined, field, layout)){
	     	this.$node && this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
		return false;
     	}
		if(val && !this.validateLength(val, len)){
			return false;
		}
		this.setData("cxPropErrorMessage", "");//No I18n
		return true;
     },
     phoneClick : function(value, id, module, entityName){
     	event.preventDefault();
		if(typeof ctiApiNotifier != "undefined"){
			ctiApiNotifier.click2Call(value, Crm.userDetails.ZGID, Crm.userDetails.USER_ID, id,'', module, entityName);//No I18n
		}
		event.stopPropagation();
     },
     phoneMouseOver : function(ele){
		if(ele.querySelector("a")){
			ele.querySelector("a").style.visibility = "visible";
		}
     },
     phoneMouseOut : function(ele){
		if(ele.querySelector("a")){
			ele.querySelector("a").style.visibility = "hidden";
		}
     },
     stopPropagation : function(){
     	event.stopPropagation();
     	return false;
     },
     toPrecision : function(value, length){
     	value = value.toString();
     	var split = value.split(".");
     	var ret = split[0];
     	var start = ret.length;
     	if(split.length < 2){
     		split[1] = "";
     	}
     	for(var i=start, j=0; i<length; i++, j++){
     		if(j == 0){
     			ret+=".";
     		}
     		if(split[1][j]){
     			ret+=split[1][j];
     		}
     		else{
	     		ret+="0";
     		}
     	}
     	return ret;
     },
     getDateObjectFromString : function(dateVal,datePattern, timeFormat)
	  {
	 	if(!dateVal)
	 	{
	 		return new Date();
	 	}
	  	if(!dateVal.getMonth)
	  	{
	  		if(!datePattern)
			{
				var colonIndex = dateVal.indexOf(":");
				if(colonIndex >-1)
				{
					timeFormat = timeFormat ? timeFormat : Crm.userDetails.TIME_FORMAT;
					if(dateVal.indexOf("AM") >1 || dateVal.indexOf("PM") >1)
					{
						timeFormat = timeFormat.replace('a','A');
					}
					datePattern += " "+timeFormat;
				}
			}
			var dateObj = $L.moment(dateVal.trim(), datePattern.toUpperCase().trim()).getDObj();//No I18n
			if(!dateObj)
			{
				dateObj= new Date();
			}
			return dateObj;
	  	}
	  	return dateVal;
	  },
	  checkBrackets : function(str){
	  	var stack = [];
	  	for(var i in str){
	  		if(str[i] == '('){
	  			stack.push(str[i]);
	  		}
	  		else if(str[i] == ')'){
	  			if(stack.length){
		  			stack.pop();
	  			}
	  			else{
	  				return false;
	  			}
	  		}
	  	}
	  	if(stack.length){
	  		return false;
	  	}
	  	return true;
	  },
	  checkCorrectInputEle : function(data){
	  	var dataSplit = data.split('');
	  	var acceptVal = ["(", ")"];
	  	for(var i=0; i<dataSplit.length; i++){
	  		var $this = dataSplit[i];
	  		if(acceptVal.indexOf($this) != -1){
	  			var next = i+1;
				var numberReg = /\d/;
  				var prev = i-1;
	  			if($this == "("){
	  				if(dataSplit[prev] != undefined){
	  					if(numberReg.test(dataSplit[prev]) || dataSplit[prev] == ")" || dataSplit[prev] == "."){
	  						return false;
	  					}
	  				}
	  				if(dataSplit[next] != undefined){
	  					var rarecase = ["/", "*"];
	  					if(rarecase.indexOf(dataSplit[next]) != -1){
	  						return false;
	  					}
	  				}
	  			}
	  			else if($this == ")"){
	  				if(dataSplit[next] != undefined){
	  					if(numberReg.test(dataSplit[next]) || dataSplit[next] == "." || dataSplit[prev] == "("){
	  						return false;
	  					}
	  				}
	  				if(dataSplit[prev] != undefined){
	  					var leftPanel = ["+", "-", "*", "/"];
	  					if(leftPanel.indexOf(dataSplit[prev]) != -1 || dataSplit[prev] == "."){
	  						return false;
	  					}
	  				}
	  			}
	  		}
	  	}
	  	return true;
	  },
	  clearLeadingZero : function(data){
			var reg = /[\d\.]+/gm; //eslint-disable-line no-useless-escape
			var test;
			while((test = reg.exec(data)) !== null) {
				test = test.toString();
			    if(test.indexOf('.') == -1 && test.match(/(^0{1,}(?!($|\.)))/g) != undefined) {
			    	data= data.replace(test, test.replace(/(^0{1,}(?!($|\.)))/g,''));
				}
			}
			return data;
		},
		checkDotCorrectInput : function(data){
			var dataSpl = data.split(/[+\-*/,]+/);
			var dataSplLength = dataSpl.length;
			for (var i = 0; i < dataSplLength; i++){
				var currLi = dataSpl[i].split('.');
				if(currLi.length > 2){
					return false;
				}
			}
			return true;
			},
		"getDateTimeInUserFormat" : function(dateObj,upCase, isView, noNeedI18n) {// no i18n
			var dtStr = this.getDateInUserDatePattern(dateObj);
			dtStr += " " + this.getTimeInUserFormat(dateObj, upCase, noNeedI18n);
			return dtStr;
		},
		showHideInfoTooltip: function(elem) {
			var infoIcon;

			if(elem) {
				infoIcon = $L(elem);
	      infoIcon.addClass('cxCurrentHovercard'); //No I18n
	      var hoverCardElem = infoIcon.next();
	      hoverCardElem[0].ltProp('show', true); //No I18n
			} else {
				infoIcon = this.$node.querySelector(".cxCurrentHovercard"); //NO I18n
	      $L(infoIcon).removeClass('cxCurrentHovercard'); //No I18n
			}
		},
		validateMandatory : function(bool, callback, field, layout){
			field = field ? field : this.data.cxPropField;
			layout = layout ? layout : this.data.cxPropLayout;
			var required = layout && field[layout] ? field[layout].required : field.required;
			if(required && bool){
				if(typeof callback != "undefined"){
					callback();
				}
				else{
					if(this.getMethods("onError")){
						this.executeMethod("onError", this.errorCodes.ERR01, this);//No I18n
					}
					else{
						this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.field.empty.check", Lyte.Component.registeredHelpers.cruxEncodeHTML(this.getData("cxPropField").field_label)));//No I18n
					}
				}
				return false;
			}
			return true;
		},
		setFocusUtil : function(){
			this.$node.focus = function(){
				var inp = this.$node.querySelector("input");
				inp = inp ? inp : this.$node.querySelector("textarea");
				inp ? inp.focus() : "";
				inp ? inp.selectionStart = inp.value.length : "";
			}.bind(this)
		},
		observeMandatoryMixin : function(sel, cls){
			var node = this.$node.querySelector(sel);
			if(  !node  || this.getData("cxPropFrom") != "create"){
				return;
			}
			var mandatory = this.data.cxPropLayout ? this.data.cxPropField[this.data.cxPropLayout].required : this.data.cxPropField.required;
			cls = cls ? cls : "mandatoryField";//No I18n
			if(mandatory){
				node.classList.add(cls);//No I18n
			}
			else{
				node.classList.remove(cls);//No I18n
			}
		},
		setSelectUtil : function(){
			this.$node.select = function(){
				var inp = this.$node.querySelector("lyte-input");
				inp ? inp.select() : "";
			}.bind(this)
		}
});
